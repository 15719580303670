.brand-page-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.brand-page-content {
  margin-top: 30px;
}

.brand-hero-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 60px;
  padding: 20px;
}

.brand-image-container {
  margin-bottom: 30px;
}

.brand-intro h2 {
  font-size: 1.8rem;
  margin-bottom: 15px;
  color: #BFA046;
}

.brand-intro p {
  font-size: 1.1rem;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;
}

.brand-services-section,
.brand-features-section {
  margin-bottom: 70px;
}

.brand-services-section h2,
.brand-features-section h2 {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 40px;
}

.services-grid,
.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
  margin-top: 30px;
}

.service-card,
.feature-card {
  height: 100%;
}

.service-card h3,
.feature-card h3 {
  font-size: 1.3rem;
  margin-bottom: 15px;
}

.service-card p,
.feature-card p {
  font-size: 1rem;
  line-height: 1.5;
}

/* Media Queries */
@media (min-width: 768px) {
  .brand-hero-section {
    flex-direction: row;
    text-align: left;
    justify-content: center;
    gap: 50px;
  }
  
  .brand-image-container {
    margin-bottom: 0;
  }
  
  .brand-intro {
    max-width: 500px;
  }
}

/* Additional Media Queries for smaller devices */
@media (max-width: 480px) {
  .brand-intro h2 {
    font-size: 1.5rem;
  }
  
  .brand-intro p {
    font-size: 0.95rem;
  }
  
  .brand-services-section h2,
  .brand-features-section h2 {
    font-size: 1.6rem;
  }
  
  .brand-hero-section {
    padding: 10px;
  }
  
  .brand-image-container img {
    max-width: 180px;
  }
  
  .service-card h3,
  .feature-card h3 {
    font-size: 1.1rem;
  }
} 