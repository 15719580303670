@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

:root {
  --primary-color: #BFA046; /* More metallic gold */
  --secondary-color: #C8B273; /* Softer metallic gold */
  --accent-color: #9C7A3C; /* Darker metallic gold */
  --text-primary: #FFFFFF;
  --text-secondary: #DDDDDD; /* Lightened for better contrast */
  --text-light: #AAAAAA; /* Lightened for better contrast */
  --bg-primary: #121212; /* Dark black */
  --bg-secondary: #1E1E1E; /* Lighter black */
  --bg-accent: #2D2D2D; /* Even lighter black */
  --spacing-xs: 0.25rem;
  --spacing-sm: 0.5rem;
  --spacing-md: 1rem;
  --spacing-lg: 2rem;
  --spacing-xl: 3rem;
  --border-radius: 8px;
  --box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  --gold-gradient: linear-gradient(135deg, #BFA046 0%, #E2D1A7 50%, #9C7A3C 100%);
  --gold-shine: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.3) 50%, rgba(255,255,255,0) 100%);
  --transition-speed: 0.3s;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  line-height: 1.6;
  color: var(--text-primary);
  background-color: var(--bg-primary);
}

a {
  color: var(--primary-color);
  text-decoration: none;
  transition: color var(--transition-speed) ease;
}

a:hover {
  color: var(--secondary-color);
}

img {
  max-width: 100%;
  height: auto;
}

button, .btn {
  cursor: pointer;
  border: none;
  border-radius: var(--border-radius);
  font-family: inherit;
  font-weight: 500;
  padding: 0.75rem 1.5rem;
  transition: all var(--transition-speed) ease;
}

.btn-link {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
}

.primary-btn {
  background: var(--gold-gradient);
  color: var(--bg-primary);
  box-shadow: 0 2px 10px rgba(212, 175, 55, 0.4);
}

.primary-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(212, 175, 55, 0.6);
}

.secondary-btn {
  background-color: transparent;
  border: 2px solid var(--primary-color);
  color: var(--primary-color);
  position: relative;
  overflow: hidden;
}

.secondary-btn:before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: var(--gold-shine);
  transition: all 0.6s ease;
}

.secondary-btn:hover:before {
  left: 100%;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: var(--spacing-md);
  background: var(--gold-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  display: inline-block;
}

p {
  margin-bottom: var(--spacing-md);
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 var(--spacing-lg);
}

/* Layout */
.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
}

/* Navbar */
.navbar {
  position: sticky;
  top: 0;
  background-color: rgba(18, 18, 18, 0.8);
  backdrop-filter: blur(10px);
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.5);
  z-index: 100;
  padding: var(--spacing-md) 0;
  border-bottom: 1px solid rgba(212, 175, 55, 0.2);
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 var(--spacing-lg);
}

.logo-container {
  font-size: 1.5rem;
  font-weight: 700;
}

.logo {
  background: var(--gold-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.nav-links {
  display: flex;
  gap: var(--spacing-lg);
}

.nav-links a, .dropdown-trigger {
  color: var(--text-primary);
  font-weight: 500;
  cursor: pointer;
  position: relative;
}

.nav-links a:after, .dropdown-trigger:after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -5px;
  left: 0;
  background: var(--gold-gradient);
  transition: width var(--transition-speed) ease;
}

.nav-links a:hover:after, .dropdown-trigger:hover:after {
  width: 100%;
}

/* Prevent hover underline on active links */
.nav-link.active a:after {
  width: 0; /* Don't show the CSS underline on active links */
}

.nav-link.active a:hover:after {
  width: 0; /* Don't show the CSS underline on active links when hovering */
}

.dropdown {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  padding: var(--spacing-md);
  min-width: 200px;
  z-index: 10;
  overflow: hidden;
  border: 1px solid rgba(212, 175, 55, 0.3);
}

.dropdown-menu a {
  display: block;
  padding: var(--spacing-sm) var(--spacing-md);
  margin-bottom: var(--spacing-xs);
  color: var(--text-secondary);
  transition: all var(--transition-speed) ease;
}

.dropdown-menu a:hover {
  background-color: rgba(212, 175, 55, 0.1);
  color: var(--primary-color);
}

/* Hero Section */
.hero-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 70vh;
  max-width: 1200px;
  margin: 0 auto;
  padding: var(--spacing-xl) var(--spacing-lg);
  gap: var(--spacing-xl);
  position: relative;
  overflow: hidden;
}

.hero-container:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at 50% 50%, rgba(212, 175, 55, 0.1) 0%, rgba(18, 18, 18, 0) 70%);
  z-index: -1;
}

.hero-content {
  flex: 1;
  max-width: 600px;
}

.hero-title {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: var(--spacing-md);
  line-height: 1.2;
}

.hero-subtitle {
  font-size: 1.25rem;
  color: var(--text-secondary);
  margin-bottom: var(--spacing-lg);
}

.highlight {
  background: var(--gold-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 700;
}

.hero-buttons {
  display: flex;
  gap: var(--spacing-md);
}

.hero-image {
  flex: 1;
  max-width: 500px;
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: var(--spacing-lg) 0;
}

.hero-image img {
  max-width: 100%;
  max-height: 400px;
  object-fit: contain;
}

.hero-image:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at 50% 50%, rgba(212, 175, 55, 0.3) 0%, rgba(18, 18, 18, 0) 70%);
  pointer-events: none;
  z-index: 1;
}

/* Brands Section */
.brands-section {
  padding: var(--spacing-xl) var(--spacing-lg);
  background-color: var(--bg-secondary);
  position: relative;
  overflow: hidden;
}

.brands-section:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: var(--gold-gradient);
  opacity: 0.5;
}

.section-header {
  text-align: center;
  max-width: 800px;
  margin: 0 auto var(--spacing-xl);
}

.section-header h2 {
  font-size: 2.5rem;
  margin-bottom: var(--spacing-sm);
}

.section-header p {
  color: var(--text-secondary);
  font-size: 1.1rem;
}

.brands-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: var(--spacing-lg);
  max-width: 1200px;
  margin: 0 auto;
}

/* Brand Card */
.brand-card {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius);
  overflow: hidden;
  box-shadow: var(--box-shadow);
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #D4AF3729; /* Use hex with opacity instead of rgba */
  transition: transform var(--transition-speed) ease, 
              box-shadow var(--transition-speed) ease;
}

.brand-card:hover {
  border-color: #D4AF3780; /* Use hex with opacity instead of rgba */
  transform: translateY(-5px);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.8);
}

.brand-card-logo {
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-md);
  background-color: var(--bg-secondary);
  border-bottom: 1px solid #D4AF3729; /* Use hex with opacity instead of rgba */
}

.brand-card-logo img {
  max-height: 80px;
  filter: drop-shadow(0 0 5px rgba(212, 175, 55, 0.5));
}

.brand-card-content {
  padding: var(--spacing-md) var(--spacing-md) var(--spacing-lg);
  flex: 1;
}

.brand-name {
  font-size: 1.5rem;
  margin-bottom: var(--spacing-sm);
}

.brand-description {
  color: var(--text-secondary);
  margin-bottom: var(--spacing-md);
}

.brand-services {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-xs);
}

.service-tag {
  font-size: 0.8rem;
  padding: 0.25rem 0.5rem;
  border-radius: 20px;
  font-weight: 500;
  background-color: #D4AF3729; /* Use hex with opacity instead of rgba */
  color: var(--primary-color);
  border: 1px solid #D4AF3733; /* Use hex with opacity instead of rgba */
}

.more-tag {
  background-color: var(--bg-accent);
  color: var(--text-secondary);
  border: 1px solid rgba(204, 204, 204, 0.1);
}

.brand-card-footer {
  padding: var(--spacing-md);
  background-color: var(--bg-accent);
  transition: background-color var(--transition-speed) ease;
  position: relative;
  overflow: hidden;
}

.brand-card-footer:before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: var(--gold-shine);
  transition: all 0.6s ease;
  z-index: 1;
}

.brand-card-footer:hover:before {
  left: 100%;
}

.brand-card-footer a {
  color: var(--text-primary);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 2;
}

.brand-card-footer:hover {
  background: #BFA046; /* Use solid color instead of gradient */
}

.brand-card-footer:hover a {
  color: var(--bg-primary);
}

/* Brand Detail Page */
.brand-detail-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 var(--spacing-lg);
}

.brand-hero {
  padding: 60px 20px;
  text-align: center;
  background-position: center;
  background-size: cover;
  position: relative;
  border-radius: 8px;
  margin-bottom: 40px;
}

.brand-hero:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, var(--bg-primary) 0%, rgba(18, 18, 18, 0) 100%);
  pointer-events: none;
}

.brand-hero-content {
  position: relative;
  z-index: 2;
}

.brand-services-section,
.brand-description-section,
.brand-features-section {
  margin-bottom: 50px;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 25px;
}

.brand-hero-content h1 {
  font-size: 3rem;
  margin-bottom: var(--spacing-md);
}

.brand-logo {
  max-width: 150px;
  max-height: 150px;
  margin-bottom: var(--spacing-md);
  filter: drop-shadow(0 0 10px rgba(212, 175, 55, 0.8));
}

.brand-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: var(--spacing-xl) var(--spacing-lg);
}

.brand-description-section,
.brand-services-section,
.brand-contact-section {
  margin-bottom: var(--spacing-xl);
  background-color: var(--bg-secondary);
  padding: var(--spacing-lg);
  border-radius: var(--border-radius);
  border: 1px solid rgba(212, 175, 55, 0.1);
  box-shadow: var(--box-shadow);
}

.contact-container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: var(--spacing-lg);
  margin-top: var(--spacing-lg);
}

.contact-info {
  background-color: var(--bg-primary);
  padding: var(--spacing-lg);
  border-radius: var(--border-radius);
  border: 1px solid rgba(212, 175, 55, 0.1);
}

.contact-item {
  margin-bottom: var(--spacing-lg);
}

.contact-item h4 {
  font-size: 1.1rem;
  margin-bottom: var(--spacing-xs);
  color: var(--primary-color);
}

.social-links {
  display: flex;
  gap: var(--spacing-md);
}

.social-link {
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: rgba(212, 175, 55, 0.1);
  color: var(--primary-color);
  border-radius: 20px;
  transition: all var(--transition-speed) ease;
  font-size: 0.9rem;
  border: 1px solid rgba(212, 175, 55, 0.2);
}

.social-link:hover {
  background-color: var(--primary-color);
  color: var(--bg-primary);
}

/* Contact Form */
.contact-form {
  background-color: var(--bg-primary);
  padding: var(--spacing-lg);
  border-radius: var(--border-radius);
  border: 1px solid rgba(212, 175, 55, 0.1);
}

.form-group {
  margin-bottom: var(--spacing-md);
}

.form-group label {
  display: block;
  margin-bottom: var(--spacing-xs);
  font-weight: 500;
  color: var(--primary-color);
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid rgba(212, 175, 55, 0.2);
  border-radius: var(--border-radius);
  font-family: inherit;
  font-size: 1rem;
  transition: border-color var(--transition-speed) ease;
  background-color: var(--bg-secondary);
  color: var(--text-primary);
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 5px rgba(212, 175, 55, 0.3);
}

.submit-btn {
  width: 100%;
  padding: 0.875rem;
  margin-top: var(--spacing-md);
  color: var(--bg-primary);
  font-weight: 600;
  background: var(--gold-gradient);
  position: relative;
  overflow: hidden;
}

.submit-btn:before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: var(--gold-shine);
  transition: all 0.6s ease;
  z-index: 1;
}

.submit-btn:hover:before {
  left: 100%;
}

.submit-btn > * {
  position: relative;
  z-index: 2;
}

.form-success {
  background-color: rgba(212, 175, 55, 0.1);
  padding: var(--spacing-lg);
  border-radius: var(--border-radius);
  text-align: center;
  border: 1px solid rgba(212, 175, 55, 0.3);
}

.success-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  background: var(--gold-gradient);
  color: var(--bg-primary);
  font-size: 2rem;
  border-radius: 50%;
  margin: 0 auto var(--spacing-md);
  box-shadow: 0 0 20px rgba(212, 175, 55, 0.5);
}

/* Footer */
.footer {
  background-color: #0A0A0A;
  color: white;
  padding-top: var(--spacing-xl);
  border-top: 1px solid rgba(212, 175, 55, 0.2);
}

.footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 var(--spacing-lg) var(--spacing-xl);
}

.footer-logo {
  flex: 1;
  min-width: 300px;
  margin-bottom: var(--spacing-lg);
}

.footer-logo span {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: var(--spacing-sm);
  display: block;
  background: var(--gold-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.footer-links {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-xl);
}

.footer-column h4 {
  margin-bottom: var(--spacing-md);
  color: var(--primary-color);
}

.footer-column ul {
  list-style: none;
}

.footer-column li {
  margin-bottom: var(--spacing-sm);
}

.footer-column a {
  color: var(--text-secondary);
  transition: color var(--transition-speed) ease;
  position: relative;
  display: inline-block;
}

.footer-column a:after {
  content: '';
  position: absolute;
  width: 0;
  height: 1px;
  bottom: -2px;
  left: 0;
  background: var(--gold-gradient);
  transition: width var(--transition-speed) ease;
}

.footer-column a:hover {
  color: var(--primary-color);
}

.footer-column a:hover:after {
  width: 100%;
}

.copyright {
  background-color: #050505;
  padding: var(--spacing-md) 0;
  text-align: center;
  color: var(--text-secondary);
  font-size: 0.875rem;
  border-top: 1px solid rgba(212, 175, 55, 0.1);
}

/* Responsive Design */
@media (max-width: 992px) {
  .hero-container {
    flex-direction: column;
    text-align: center;
    padding: var(--spacing-lg) var(--spacing-md);
  }

  .hero-content {
    order: 1;
  }

  .hero-image {
    order: 0;
    margin-bottom: var(--spacing-lg);
  }

  .contact-container {
    grid-template-columns: 1fr;
  }
  
  .brands-grid {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
  }

  .footer-links {
    margin-top: var(--spacing-lg);
  }
  
  /* Remove this conflicting style since we're handling it differently */
  /* .brands-grid {
    grid-template-columns: 1fr;
  } */
}

/* Gold shine animations */
@keyframes goldShine {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

.gold-shine {
  background: linear-gradient(90deg, var(--bg-primary) 0%, var(--primary-color) 25%, var(--secondary-color) 50%, var(--primary-color) 75%, var(--bg-primary) 100%);
  background-size: 200% 100%;
  animation: goldShine 3s linear infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

/* Particle effects */
.particles {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: -1;
}

/* Navigation Link Styling */
.nav-link {
  position: relative;
  margin: 0 15px;
}

.nav-link a {
  color: inherit;
  text-decoration: none;
  padding: 5px 0;
  position: relative;
  display: inline-block;
}

.nav-link.active a {
  color: var(--secondary-color);
  font-weight: 500;
}

.mobile-nav-link {
  position: relative;
  margin: 12px 0;
  padding: 3px 0;
}

.mobile-nav-link a {
  color: inherit;
  text-decoration: none;
  font-size: 1.1rem;
  position: relative;
  display: inline-block;
}

.mobile-nav-link.active a {
  color: var(--secondary-color);
  font-weight: 500;
}

/* Page Title Styling */
.page-title-container {
  margin-bottom: 40px;
  background-color: rgba(18, 18, 18, 0.6);
  border-bottom: 1px solid rgba(191, 160, 70, 0.2);
}

/* Breadcrumbs */
.breadcrumb-navigation {
  background-color: rgba(18, 18, 18, 0.8);
  border-bottom: 1px solid rgba(191, 160, 70, 0.1);
}

/* Add styles for the brands page */
.brands-page {
  padding: 0 0 60px 0;
}

.brands-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 30px;
  padding: 0 20px;
  max-width: 1200px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .brands-grid {
    grid-template-columns: 1fr;
  }
}

/* Home page styles */
.home-featured-brands {
  padding: 20px 0 60px 0;
}

/* Contact Page Styles */
.contact-page {
  min-height: 100vh;
}

.contact-main {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--spacing-xl);
  margin: var(--spacing-xl) 0;
}

.contact-info-section {
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius);
  padding: var(--spacing-lg);
  border: 1px solid #D4AF3729;
  box-shadow: var(--box-shadow);
}

.contact-info-section h2,
.inquiry-types-section h2 {
  margin-bottom: var(--spacing-md);
}

.contact-email-highlight {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  margin: var(--spacing-lg) 0;
  padding: var(--spacing-md);
  background-color: #D4AF3729;
  border-radius: var(--border-radius);
  border: 1px solid #D4AF3733;
}

.contact-icon {
  font-size: 2rem;
  color: var(--primary-color);
}

.gold-email {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--primary-color);
  display: inline-block;
  margin-top: 0;
  line-height: 1.2;
}

.gold-email:hover {
  text-decoration: underline;
}

.contact-address {
  margin-top: var(--spacing-lg);
}

.inquiry-types-section {
  margin: var(--spacing-xl) 0;
}

.inquiry-types-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: var(--spacing-lg);
  margin-top: var(--spacing-lg);
}

.inquiry-card {
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius);
  padding: var(--spacing-lg);
  border: 1px solid #D4AF3729;
  transition: transform var(--transition-speed) ease, 
              box-shadow var(--transition-speed) ease;
  height: 100%;
}

.inquiry-icon {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: var(--spacing-md);
}

.inquiry-card h3 {
  margin-bottom: var(--spacing-sm);
}

.contact-note {
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius);
  padding: var(--spacing-lg);
  border: 1px solid #D4AF3729;
  margin-bottom: var(--spacing-xl);
  text-align: center;
}

@media (max-width: 768px) {
  .inquiry-types-grid {
    grid-template-columns: 1fr;
  }
}

/* About Page Styles */
.about-page {
  min-height: 100vh;
}

.about-content {
  margin: var(--spacing-xl) 0;
}

.about-section {
  margin-bottom: var(--spacing-xl);
  background-color: var(--bg-secondary);
  padding: var(--spacing-lg);
  border-radius: var(--border-radius);
  border: 1px solid #D4AF3729;
  box-shadow: var(--box-shadow);
}

.about-section h2 {
  margin-bottom: var(--spacing-md);
  position: relative;
  display: inline-block;
}

.about-section h2::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 2px;
  background: var(--gold-gradient);
  border-radius: 1px;
}

.about-section p {
  margin-bottom: var(--spacing-md);
  line-height: 1.6;
}

.about-list {
  margin: var(--spacing-md) 0;
  padding-left: var(--spacing-lg);
}

.about-list li {
  margin-bottom: var(--spacing-sm);
  position: relative;
}

.about-list li::before {
  content: '•';
  color: var(--primary-color);
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

@media (max-width: 768px) {
  .about-section {
    padding: var(--spacing-md);
  }
}

/* Privacy Policy and Terms of Service Page Styles */
.privacy-policy-page,
.terms-of-service-page {
  padding-bottom: 60px;
}

.privacy-content,
.terms-content {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
}

.policy-section,
.terms-section {
  margin-bottom: 40px;
  background-color: rgba(18, 18, 18, 0.6);
  border: 1px solid rgba(191, 160, 70, 0.2);
  border-radius: 8px;
  padding: 25px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.policy-section:hover,
.terms-section:hover {
  border-color: rgba(191, 160, 70, 0.4);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);
}

.policy-section h2,
.terms-section h2 {
  color: #BFA046;
  font-size: 1.6rem;
  margin-top: 0;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(191, 160, 70, 0.3);
  padding-bottom: 10px;
}

.policy-section h3,
.terms-section h3 {
  color: #C8B273;
  font-size: 1.3rem;
  margin-top: 20px;
  margin-bottom: 10px;
}

.policy-section p,
.terms-section p {
  margin-bottom: 15px;
  line-height: 1.6;
}

.policy-list,
.terms-list {
  margin: 15px 0;
  padding-left: 20px;
}

.policy-list li,
.terms-list li {
  margin-bottom: 8px;
  line-height: 1.5;
  position: relative;
  padding-left: 5px;
}

.policy-list li::before,
.terms-list li::before {
  content: "•";
  color: #BFA046;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.contact-info {
  background-color: rgba(191, 160, 70, 0.1);
  border-left: 3px solid #BFA046;
  padding: 15px;
  border-radius: 0 4px 4px 0;
  margin-top: 20px;
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .policy-section,
  .terms-section {
    padding: 20px;
    margin-bottom: 30px;
  }
  
  .policy-section h2,
  .terms-section h2 {
    font-size: 1.4rem;
  }
  
  .policy-section h3,
  .terms-section h3 {
    font-size: 1.2rem;
  }
}

@media (max-width: 480px) {
  .policy-section,
  .terms-section {
    padding: 15px;
    margin-bottom: 20px;
  }
  
  .policy-section h2,
  .terms-section h2 {
    font-size: 1.3rem;
  }
  
  .policy-section h3,
  .terms-section h3 {
    font-size: 1.1rem;
  }
}

/* Mobile Menu Styles */
.mobile-menu-button {
  display: none;
  cursor: pointer;
  z-index: 101;
}

.menu-icon {
  width: 30px;
  height: 2px;
  margin: 15px 0;
  position: relative;
}

.menu-icon-top,
.menu-icon-middle,
.menu-icon-bottom {
  display: block;
  width: 30px;
  height: 2px;
  position: absolute;
  transition: all 0.3s ease;
  border-radius: 1px;
}

.menu-icon-top {
  top: -8px;
}

.menu-icon-middle {
  top: 0;
}

.menu-icon-bottom {
  top: 8px;
}

.mobile-menu {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  width: 80%;
  max-width: 350px;
  height: 100vh;
  padding: 80px 20px 20px;
  z-index: 100;
}

.mobile-nav-links {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .desktop-nav {
    display: none;
  }
  
  .mobile-menu-button {
    display: block;
  }
  
  .mobile-menu {
    display: block;
  }
  
  .navbar-container {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacing-md) var(--spacing-lg);
  }
  
  .logo-container {
    font-size: 1.2rem;
  }
  
  .brands-grid {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 20px;
    padding: 0 15px;
  }
  
  .hero-title {
    font-size: 2.2rem;
  }
  
  .hero-subtitle {
    font-size: 1rem;
  }
  
  .service-card, .feature-card {
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .navbar-container {
    padding: var(--spacing-md) var(--spacing-md);
  }
  
  .logo-text {
    font-size: 1.1rem !important;
  }
  
  .mobile-menu {
    width: 100%;
    max-width: none;
  }
  
  .hero-title {
    font-size: 1.8rem;
  }
  
  .brand-card {
    margin: 0 10px;
  }
  
  .section-header h2 {
    font-size: 1.6rem;
  }
  
  .footer-logo span {
    font-size: 1.3rem;
  }
  
  .brands-grid {
    grid-template-columns: 1fr;
  }
}

/* Make sure touch targets are large enough on mobile */
@media (max-width: 768px) {
  .nav-link a, 
  .mobile-nav-link a,
  .footer-column a,
  .btn,
  .btn-link {
    padding: 8px 0;
    min-height: 44px;
    display: flex;
    align-items: center;
  }
  
  .form-group input,
  .form-group textarea,
  .submit-btn {
    min-height: 44px;
  }
}

/* Responsive brand detail page */
@media (max-width: 768px) {
  .brand-hero {
    padding: 40px 15px;
  }
  
  .brand-logo {
    max-width: 200px !important;
    max-height: 120px !important;
  }
  
  .brand-hero-content h1 {
    font-size: 2rem;
    margin: 15px 0;
  }
  
  .brand-tagline {
    font-size: 1rem;
  }
  
  .brand-content {
    padding: 0 15px;
  }
  
  .services-grid {
    grid-template-columns: 1fr;
  }
  
  .breadcrumb-navigation {
    font-size: 0.8rem;
    padding: 10px 15px;
  }
}

@media (max-width: 480px) {
  .brand-hero {
    padding: 30px 10px;
  }
  
  .brand-logo {
    max-width: 150px !important;
    max-height: 100px !important;
  }
  
  .brand-hero-content h1 {
    font-size: 1.8rem;
  }
  
  .brand-content section h2 {
    font-size: 1.5rem;
  }
}
