.brand-page-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.brand-page-content {
  margin-top: 30px;
}

.brand-hero-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 60px;
  padding: 20px;
}

.brand-image-container {
  margin-bottom: 30px;
}

.brand-intro h2 {
  font-size: 1.8rem;
  margin-bottom: 15px;
  color: #BFA046;
}

.brand-intro p {
  font-size: 1.1rem;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;
}

/* App Showcase Section Styles */
.app-showcase-section {
  margin-bottom: 70px;
  padding: 20px 15px;
  background: rgba(18, 18, 18, 0.5);
  border-radius: 10px;
  border: 1px solid rgba(191, 160, 70, 0.2);
}

.app-showcase-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  margin-top: 30px;
}

.app-icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app-description {
  max-width: 700px;
}

.app-description p {
  line-height: 1.7;
}

.app-description ul {
  margin-left: 20px;
}

.brand-services-section,
.brand-features-section {
  margin-bottom: 70px;
}

.brand-services-section h2,
.brand-features-section h2 {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 40px;
}

.services-grid,
.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
  margin-top: 30px;
}

.service-card,
.feature-card {
  height: 100%;
}

.service-card h3,
.feature-card h3 {
  font-size: 1.3rem;
  margin-bottom: 15px;
}

.service-card p,
.feature-card p {
  font-size: 1rem;
  line-height: 1.5;
}

/* Media Queries */
@media (min-width: 768px) {
  .brand-hero-section {
    flex-direction: row;
    text-align: left;
    justify-content: center;
    gap: 50px;
  }
  
  .brand-image-container {
    margin-bottom: 0;
  }
  
  .brand-intro {
    max-width: 500px;
  }
  
  .app-showcase-container {
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }
  
  .app-icon-container {
    flex: 0 0 auto;
  }
  
  .app-description {
    flex: 1;
    padding-left: 20px;
  }
}

/* Additional Media Queries for smaller devices */
@media (max-width: 480px) {
  .brand-intro h2 {
    font-size: 1.5rem;
  }
  
  .brand-intro p {
    font-size: 0.95rem;
  }
  
  .brand-services-section h2,
  .brand-features-section h2,
  .app-showcase-section h2 {
    font-size: 1.6rem;
  }
  
  .brand-hero-section {
    padding: 10px;
  }
  
  .brand-image-container img {
    max-width: 180px;
  }
  
  .service-card h3,
  .feature-card h3 {
    font-size: 1.1rem;
  }
  
  .app-showcase-section {
    padding: 20px 15px;
  }
  
  .app-icon {
    width: 120px !important;
    height: 120px !important;
  }
  
  .app-icon > div {
    width: 80px !important;
    height: 80px !important;
    font-size: 2.5rem !important;
  }
  
  .app-description h3 {
    font-size: 1.3rem;
  }
  
  .app-description p,
  .app-description li {
    font-size: 0.9rem;
  }
  
  .app-privacy-button {
    font-size: 0.8rem !important;
    padding: 6px 12px !important;
  }
} 