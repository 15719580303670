.privacy-policy-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
}

.privacy-policy-content {
  background: rgba(18, 18, 18, 0.7);
  border-radius: 10px;
  border: 1px solid rgba(191, 160, 70, 0.2);
  padding: 30px;
  margin-bottom: 40px;
}

.privacy-section {
  margin-bottom: 40px;
}

.privacy-section:last-child {
  margin-bottom: 0;
}

.privacy-section h2 {
  font-size: 1.8rem;
  margin-bottom: 20px;
  color: #BFA046;
  position: relative;
  display: inline-block;
}

.privacy-section h2::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, #BFA046, #C8B273, rgba(191, 160, 70, 0));
  border-radius: 1px;
}

.privacy-section h3 {
  font-size: 1.3rem;
  margin: 25px 0 15px;
  color: #C8B273;
}

.privacy-section p {
  margin-bottom: 15px;
  line-height: 1.7;
}

.privacy-section ul {
  margin: 15px 0;
  padding-left: 25px;
}

.privacy-section li {
  margin-bottom: 10px;
  line-height: 1.5;
}

.contact-block {
  background: rgba(191, 160, 70, 0.1);
  padding: 20px;
  border-radius: 8px;
  border: 1px solid rgba(191, 160, 70, 0.3);
  margin-top: 15px;
}

.contact-block p {
  margin-bottom: 10px;
}

.contact-block p:last-child {
  margin-bottom: 0;
}

/* Media queries for responsive design */
@media (max-width: 768px) {
  .privacy-policy-content {
    padding: 20px;
  }
  
  .privacy-section h2 {
    font-size: 1.5rem;
  }
  
  .privacy-section h3 {
    font-size: 1.2rem;
  }
  
  .back-button-container button {
    padding: 8px 16px !important;
    font-size: 0.9rem !important;
  }
}

@media (max-width: 480px) {
  .privacy-policy-content {
    padding: 15px;
  }
  
  .privacy-section {
    margin-bottom: 30px;
  }
  
  .privacy-section h2 {
    font-size: 1.3rem;
  }
  
  .privacy-section h3 {
    font-size: 1.1rem;
  }
  
  .privacy-section p,
  .privacy-section li {
    font-size: 0.9rem;
  }
  
  .contact-block {
    padding: 15px;
  }
} 